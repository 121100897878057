/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import { useStaticQuery, graphql } from "gatsby";

const Services = ({ gridItems }) => {
  const data = useStaticQuery(graphql`
    query ServicesQuery {
      markdownRemark {
        frontmatter {
          services {
            title
            imageObject {
              title
              alt
              image {
                publicURL
              }
            }
            service {
              heading
              description
              imageObject {
                title
                alt
                image {
                  publicURL
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div id="Leistungen" sx={{ pt: 4, mt: [5, 6, 6] }}>
      <div sx={{ display: "flex" }}>
        <span
          sx={{
            width: "100%",
            height: "8px",
            backgroundColor: ["white", "primary", "primary"],
            alignSelf: "flex-end",
          }}
        />
        <h2
          sx={{
            width: "auto",
            margin: "0px",
            lineHeight: ["32px", "42px", "42px"],
            fontSize: [7, 9, 9],
          }}
        >
          Leistungen
        </h2>
        <span sx={{ width: "100%" }} />
      </div>
      <div sx={{ px: [3, "0px", 0] }}>
        <div
          sx={{
            maxWidth: "1000px",
            margin: "0 auto",
            display: ["flex", "block", "block"],
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {data.markdownRemark.frontmatter.services.map((el) => {
            return (
              <React.Fragment>
                <div
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    mt: [4, 6, 6],
                  }}
                >
                  <img
                    loading="lazy"
                    sx={{
                      height: 14,
                      width: 14,
                      mr: 2,
                    }}
                    src={el.imageObject.image.publicURL}
                    alt={el.imageObject.alt}
                    title={el.imageObject.title}
                  />
                  <h3
                    sx={{
                      fontSize: [5, 6, 6],
                      margin: "0px",
                      textAlign: "center",
                      verticalAlign: "center",
                    }}
                  >
                    {el.title}
                  </h3>
                </div>
                <div
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: ["column", "row", "row"],
                    justifyContent: [
                      "flex-start",
                      "space-evenly",
                      "space-evenly",
                    ],
                    flexWrap: "wrap",
                  }}
                >
                  {el.service.map((el) => {
                    return (
                      <React.Fragment>
                        <div
                          sx={{
                            width: ["100%", "30%", "30%"],
                            display: "flex",
                            flexDirection: ["row", "column", "column"],
                            justifyContent: ["flex-start", "center", "center"],
                            alignItems: "center",
                            mt: [0, 6, 6],
                            py: 3,
                            transition: "transform .2s",
                            "&:nth-of-type(1)": {
                              mt: [0, 5, 5],
                            },
                            "&:nth-of-type(2)": {
                              mt: [0, 5, 5],
                            },
                            "&:nth-of-type(3)": {
                              mt: [0, 5, 5],
                            },
                            "&:hover": {
                              transform: ["none", "none", "scale(1.15)"],
                            },
                          }}
                        >
                          <img
                            loading="lazy"
                            sx={{
                              height: [2, 4, 4],
                              width: [2, 4, 4],
                              color: "primary",
                            }}
                            src={el.imageObject.image.publicURL}
                            alt={el.imageObject.alt}
                            title={el.imageObject.title}
                          />
                          <p
                            sx={{
                              hyphens: "auto",
                              textAlign: ["start", "center", "center"],
                              fontSize: [2, 4, 4],
                              margin: "0px",
                              mt: [0, 3, 3],
                              ml: [2, 0, 0],
                            }}
                          >
                            {el.heading}
                          </p>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Services;
