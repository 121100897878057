/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";

import { IndexPageTemplate } from "../templates/index-page";
import Layout from "../components/Layout";

import { Helmet } from "react-helmet";
import useSiteMetadata from "../components/SiteMetadata";
import { withPrefix } from "gatsby";

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { title, description } = useSiteMetadata();
  return (
    <Layout>
      <Helmet>
        <html lang="de" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/Arzt-Signer-bei-Untersuchung.jpeg`}
        />
      </Helmet>
      <IndexPageTemplate
        herosection={frontmatter.herosection}
        mainpitch={frontmatter.mainpitch}
        openingHours={frontmatter.openingHours}
        news={frontmatter.news}
        benefits={frontmatter.benefits}
        services={frontmatter.services}
        team={frontmatter.team || null}
      />
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        templateKey
        benefits {
          blurbs {
            description
            heading
          }
          heading
        }
        herosection {
          address
          imageObject {
            title
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 5000, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          subtitle
          telephone
          title
        }
        mainpitch
        news
        openingHours {
          days {
            day
            times {
              time
            }
          }
          title
        }
        services {
          service {
            description
            heading
            imageObject {
              alt
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          title
          imageObject {
            alt
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        team {
          title
          teamMember {
            description
            fullName
            imageObject {
              image {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
