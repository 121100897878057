/** @jsx jsx */
import { jsx } from "theme-ui";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const Contact = ({ gridItems }) => {
  return (
    <div id="Kontakt" sx={{ pt: 4, mt: [5, 6, 6] }}>
      <div sx={{ display: "flex" }}>
        <span
          sx={{
            width: "100%",
            height: "8px",
            backgroundColor: ["white", "primary", "primary"],
            alignSelf: "flex-end",
          }}
        />
        <h2
          sx={{
            width: "auto",
            margin: "0px",
            lineHeight: ["32px", "42px", "42px"],
            fontSize: [7, 9, 9],
          }}
        >
          Kontakt
        </h2>
        <span sx={{ width: "100%" }} />
      </div>
      <div sx={{ px: [3, 5, 5] }}>
        <div sx={{ maxWidth: "1000px", margin: "0 auto" }}>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row", "row"],
              py: [4, 5, 5],
            }}
          >
            <h3 sx={{ fontSize: [4, 5, 5], lineHeight: 1, margin: "0px" }}>
              So erreichen Sie uns:
            </h3>
            <div
              sx={{
                width: "auto",
                height: "auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                pl: [0, 4, 4],
                pt: [3, 0, 0],
              }}
            >
              <a
                href="https://goo.gl/maps/f3f6vaCNTMPmi4Cy8"
                sx={{
                  height: "50%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  sx={{ color: "primary", fontSize: [5, 7, 7] }}
                />
                <span
                  sx={{
                    color: "black",
                    fontWeight: "text",
                    fontSize: [2, 4, 4],
                    pl: 3,
                  }}
                >
                  Regensburger Str. 21 <br /> 93138 Lappersdorf
                </span>
              </a>
              <a
                href="tel:+4994181220"
                sx={{
                  height: "50%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  pt: 3,
                  textDecoration: "none",
                }}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  sx={{ color: "primary", fontSize: [4, 5, 5] }}
                />
                <span
                  sx={{
                    color: "black",
                    fontWeight: "bold",
                    fontSize: [2, 4, 4],
                    lineHeight: 1,
                    pl: 3,
                  }}
                >
                  0941 81220
                </span>
              </a>
            </div>
          </div>
          <div sx={{ display: "flex", justifyContent: "center" }}>
            <iframe
              loading="lazy"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10459.95268768404!2d12.09248455239257!3d49.048848482197734!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbcd1fc472a13b8ea!2sChristian%20Signer%20Arzt%20f%C3%BCr%20Allgemeinmedizin!5e0!3m2!1sde!2sde!4v1600077941918!5m2!1sde!2sde"
              sx={{ width: "100%", height: ["80vw", "400px", "600px"] }}
              title="Hausarzt Signer Google Maps"
              alt="Hausarzt Signer Google Maps"
              frameborder="0"
              allowfullscreen=""
              aria-hidden="false"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
