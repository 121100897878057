/** @jsx jsx */
import { jsx } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

const Mainpitch = ({ gridItems }) => {
  const data = useStaticQuery(graphql`
    query MainpitchQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          mainpitch
        }
      }
    }
  `);

  const content = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(data.markdownRemark.frontmatter.mainpitch)
    .toString();

  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignSelf: "flex-start",
        pl: [0, 4, 5],
        pt: [4, 4, 5],
        h1: {
          hyphens: "none",
          margin: "0px",
          lineHeight: 1,
          fontSize: [7, 9, 9],
          mb: 3,
        },
        code: {
          margin: "0px",
          fontFamily: "Helvetica",
          color: "primary",
          lineHeight: 1,
          fontSize: [7, 9, 9],
          mb: 4,
        },
        p: {
          margin: "0px",
          lineHeight: 1.5,
          fontSize: [2, 4, 4],
          mb: 2,
          "&:last-of-type": {
            mb: "0px",
          },
        },
      }}
    />
  );
};
export default Mainpitch;
