/** @jsx jsx */
import { jsx } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";

import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";

const News = ({ gridItems }) => {
  const data = useStaticQuery(graphql`
    query NewsQuery {
      markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
        frontmatter {
          news
        }
      }
    }
  `);

  if (data.markdownRemark.frontmatter.news !== "empty") {
    const content = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(data.markdownRemark.frontmatter.news)
      .toString()
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>")
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>")
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>")
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>")
      .replace("<h1>", "<h3>")
      .replace("</h1>", "</h3>");

    return (
      <div id="Aktuelles" sx={{ pt: 4, mt: [5, 6, 6] }}>
        <div sx={{ display: "flex" }}>
          <span
            sx={{
              width: "100%",
              height: "8px",
              backgroundColor: ["white", "primary", "primary"],
              alignSelf: "flex-end",
              mr: 1,
            }}
          />
          <h2
            sx={{
              width: "auto",
              margin: "0px",
              lineHeight: ["32px", "42px", "42px"],
              fontSize: [7, 9, 9],
            }}
          >
            Aktuelles
          </h2>
          <span sx={{ width: "100%" }} />
        </div>

        <div sx={{ px: [3, 5, 5], pt: 5 }}>
          <div
            dangerouslySetInnerHTML={{ __html: content }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignSelf: "flex-start",
              pb: "0px",
              maxWidth: "1000px",
              margin: "0 auto",
              h3: {
                margin: "0px",
                lineHeight: [1, 1, 1],
                fontSize: [4, 6, 6],
                my: [3, 4, 4],
              },
              p: {
                margin: "0px",
                mb: ["0.8rem", "1rem", "1rem"],
                lineHeight: 1.5,
                fontSize: [2, 4, 4],
                "&:last-of-type": {
                  mb: "0px",
                },
              },
            }}
          />
        </div>
      </div>
    );
  } else {
    return null;
  }
};
export default News;
