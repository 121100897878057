/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";

import Img from "gatsby-image";

import Benefits from "../components/Benefits";
import Mainpitch from "../components/Mainpitch";
import News from "../components/News";
import Team from "../components/Team";
import Services from "../components/Services";
import Contact from "../components/Contact";

export const IndexPageTemplate = ({
  herosection,
  openingHours,
  news,
  mainpitch,
  benefits,
  services,
  team,
}) => (
    <React.Fragment>
      <section sx={{ display: "flex", flexDirection: "column", mt: -4 }}>
        <Img
          loading="eager"
          sx={{
            width: "100%",
          }}
          fluid={
            !!herosection.imageObject.image.childImageSharp
              ? herosection.imageObject.image.childImageSharp.fluid
              : herosection.imageObject.image
          }
          alt={herosection.imageObject.alt}
          title={herosection.imageObject.title}
        ></Img>
        <div sx={{ px: [3, 3, 5] }}>
          <div
            sx={{
              display: "flex",
              flexDirection: ["column", "row", "row"],
              alignItems: "center",
              maxWidth: "1000px",
              margin: "0 auto",
            }}
          >
            <div
              sx={{
                backgroundColor: "muted",
                minWidth: ["0px", "320px", "320px"],
                maxWidth: [null, "320px", "320px"],
                width: ["100%", "100%", "35%"],
                padding: 4,
                color: "white",
                marginTop: [-4, -5, -5],
                zIndex: "2",
              }}
            >
              <h2
                sx={{
                  fontSize: [4, 6, 6],
                  marginTop: "0px",
                  marginBottom: 4,
                  lineHeight: 1,
                  fontWeight: "lightbold",
                }}
              >
                {openingHours.title}:
            </h2>
              {openingHours.days.map((el) => {
                return (
                  <React.Fragment>
                    <div
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: 4,
                        fontSize: [2, 4, 4],
                        "&:last-of-type": {
                          marginBottom: 0,
                        },
                      }}
                    >
                      <p
                        sx={{
                          color: "white",
                          margin: "0px",
                          lineHeight: 1,
                        }}
                      >
                        {el.day}:
                    </p>
                      <div sx={{ display: "flex", flexDirection: "column" }}>
                        {el.times.map((element) => {
                          return (
                            <React.Fragment>
                              <p
                                sx={{
                                  margin: "0px",
                                  lineHeight: 1,
                                  textAlign: "right",
                                }}
                              >
                                {element.time}
                              </p>
                            </React.Fragment>
                          );
                        })}
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <Mainpitch />
          </div>
        </div>
        <News />
        <Benefits />
        <Team />
        <Services />
        <Contact />
      </section>
    </React.Fragment>
  );
