/** @jsx jsx */
import { jsx } from "theme-ui";

import { useStaticQuery, graphql } from "gatsby";

const Benefits = ({ gridItems }) => {
  const data = useStaticQuery(graphql`
    query BenefitsQuery {
      markdownRemark {
        frontmatter {
          benefits {
            heading
            blurbs {
              description
              heading
            }
          }
        }
      }
    }
  `);

  return (
    <div id="Profil" sx={{ pt: 4, mt: [5, 6, 6] }}>
      <div sx={{ display: "flex" }}>
        <span
          sx={{
            width: "100%",
            height: "8px",
            backgroundColor: ["white", "primary", "primary"],
            alignSelf: "flex-end",
          }}
        />
        <h2
          sx={{
            width: "auto",
            margin: "0px",
            lineHeight: ["32px", "42px", "42px"],
            fontSize: [7, 9, 9],
          }}
        >
          {data.markdownRemark.frontmatter.benefits.heading}
        </h2>
        <span sx={{ width: "100%" }} />
      </div>
      <div sx={{ mt: 5 }}>
        {data.markdownRemark.frontmatter.benefits.blurbs.map((item) => (
          <div
            key={item.text}
            sx={{
              ":nth-of-type(2n+1)": {
                backgroundColor: "primary",
              },
              ":nth-of-type(2n+0)": {
                backgroundColor: "secondary",
              },
              ":nth-of-type(3n)": {
                backgroundColor: "tertiary",
              },
              padding: [3, 5, 5],
            }}
          >
            <div sx={{ maxWidth: "1000px", margin: "0 auto" }}>
              <h4
                sx={{
                  fontSize: [4, 5, 5],
                  margin: 0,
                  mb: [2, 3, 3],
                  color: "white",
                }}
              >
                {item.heading}
              </h4>
              <p sx={{ fontSize: [2, 4, 4], margin: 0, color: "white" }}>
                {item.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Benefits;
