/** @jsx jsx */
import { jsx } from "theme-ui";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

import { useStaticQuery, graphql } from "gatsby";

const TeamGrid = ({ items }) => {
  const data = useStaticQuery(graphql`
    query TeamQuery {
      markdownRemark {
        frontmatter {
          team {
            title
            teamMember {
              description
              fullName
              imageObject {
                alt
                title
                image {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return (
    <div id="Praxisteam" sx={{ pt: 4, mt: [5, 6, 6] }}>
      <div sx={{ display: "flex", width: "100%" }}>
        <span
          sx={{
            width: "100%",
            height: "8px",
            backgroundColor: ["white", "primary", "primary"],
            alignSelf: "flex-end",
          }}
        />
        <h2
          sx={{
            width: "auto",
            margin: "0px",
            lineHeight: ["32px", "42px", "42px"],
            fontSize: [7, 9, 9],
          }}
        >
          {data.markdownRemark.frontmatter.team.title}
        </h2>
        <span sx={{ width: "100%" }} />
      </div>
      <div sx={{ px: [3, 5, 5] }}>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            maxWidth: "1000px",
            margin: "0 auto",
          }}
        >
          {data.markdownRemark.frontmatter.team.teamMember.map((item) => (
            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "row", "row"],
                alignSelf: "flex-start",
                width: "100%",
                mt: [4, 5, 5],
                ".teamDescription": {
                  pl: [0, 5, 5],
                  pr: 0,
                },
                "&:nth-of-type(odd)": {
                  display: "flex",
                  flexDirection: ["column", "row-reverse", "row-reverse"],
                  alignSelf: "flex-end",
                  width: "100%",
                  mt: [4, 5, 5],
                  ".teamDescription": {
                    pr: [0, 5, 5],
                    pl: 0,
                  },
                },
                "&:first-of-type .teamDescription": {
                  mt: "0px",
                },
              }}
            >
              <div sx={{ width: ["100%", "40%", "35%"] }}>
                <PreviewCompatibleImage
                  sxProp={{
                    width: "100%",
                    height: "auto",
                    mt: [0, -3, -4],
                  }}
                  title={item.imageObject.title}
                  alt={item.imageObject.alt}
                  childImageSharp={item.imageObject.image.childImageSharp}
                  image={item.imageObject.image}
                />
              </div>
              <div
                className="teamDescription"
                sx={{ width: ["100%", "60%", "65%"] }}
              >
                <h4
                  sx={{
                    variant: "text.heading",
                    fontSize: [4, 6, 6],
                    mt: 3,
                    mb: "0px",
                  }}
                >
                  {item.fullName}
                </h4>
                {!!item.description && (
                  <p
                    sx={{
                      variant: "text.body",
                      fontSize: [2, 4, 4],
                      mt: 2,
                      mb: "0px",
                    }}
                  >
                    {item.description}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeamGrid;
